<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <page-title>
      <template #title>
        <span class="h4 text-primary">Departments</span>
      </template>

      <template #button>
        <div class="" v-if="roles != 'Manager'">
          <button
            class="btn btn-primary"
            @click="goToRoute('/admin/department/new')"
          >
            <font-awesome-icon class="mr-1" icon="plus-circle" />
            Add new
          </button>
        </div>
      </template>
    </page-title>

    <!--      Page Body-->
    <div class="row pt-2">
      <div class="col-12">
        <template v-if="departments.length > 0">
          <card-row
            detailsCol="8"
            :avatar="false"
            v-for="item in departments"
            v-bind:key="item.id"
          >
            <!--        Details-->
            <template v-slot:details>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="d-flex">
                    <div class="">
                      <span
                        class="text-primary text-decoration-none font-weight-bold"
                      >
                        {{ item.name }}
                      </span>
                    </div>
                    <span
                      @click="goToDeptEmployee(item.id, item.name)"
                      class="cursor-pointer text-muted ml-2"
                    >
                      ({{ item.employeesCount }}
                      {{ item.employeesCount > 1 ? "Employees" : "Employee" }})
                    </span>

                    <span
                      @click.prevent="editDepartment(item.id)"
                      class="cursor-pointer text-muted ml-2"
                    >
                      ({{ item.shiftsCount }}
                      {{ item.shiftsCount > 1 ? "Shifts" : "Shift" }})
                    </span>
                  </div>
                </div>

                <div class="text-muted col-md-4 col-sm-12">
                  Status:
                  <span
                    :class="
                      item.isActive
                        ? `badge badge-pill badge-primary`
                        : `badge badge-pill badge-secondary`
                    "
                  >
                    {{ item.isActive ? "Active" : "Inactive" }}
                  </span>
                </div>
              </div>
            </template>

            <!--        Actions-->
            <template v-slot:actions v-if="roles != 'Manager'">
              <!--                    edit-->
              <button
                @click.prevent="editDepartment(item.id)"
                class="btn btn-sm btn-primary px-2"
              >
                <font-awesome-icon class="mr-1" icon="pencil-alt" />
                Edit
              </button>
            </template>
          </card-row>
        </template>
        <template v-else>
          <div class="card mb-3 shadow-sm">
            <div class="card-body border-0 rounded py-3">
              <span class="h6">No Data Available</span>
            </div>
          </div>
        </template>
      </div>

      <!--        Pagination-->
      <PaginationComponent
        v-if="totalCount > 15"
        :page-count="totalPages"
        :perPage="perPage"
        :click-handler="goToPage"
      ></PaginationComponent>
    </div>
  </div>
</template>

<script src="./department.ts" />

<style scoped></style>
