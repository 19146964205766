import { Options, Vue } from "vue-class-component";
import { IDepartment } from "@/types/Department";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AuthStore from "@/store/auth-store";
import PaginationComponent from "@/components/PaginationComponent.vue";
import store from "@/store";

@Options({
  name: "Department",
  components: {
    Breadcrumb,
    PaginationComponent,
  },
})
export default class Department extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  department: IDepartment = {
    id: 0,
    name: "",
    isActive: false,
    employeesCount: 0,
  };
  departments: IDepartment[] = [];
  page = 0;
  perPage = 15;
  totalCount = 0;
  crumbs: any = ["Dashboard", "Department"];

  roles = AuthStore.getTokenData().Roles;

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  goToPage(page: number) {
    return this.workForceService
      .get<IDepartment[]>(
        `/departments?PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.departments = response.content.items);
      });
  }

  async initialize() {
    await this.workForceService
      .get<IDepartment[]>(
        `/departments?PageIndex=${this.page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.departments = response.content.items;
      });
  }

  filterData = [
    {
      properties: {
        key: "DepartmentId",
        dataType: "dropDowns",
        dropDownOptions: [] as {
          value: string;
          label: string;
        }[],
      },
      query: -1,
    },
  ];
  goToDeptEmployee(id: number, name: string) {
    this.filterData[0].query = id;
    this.departments.forEach((item: { id: any; name: any }) => {
      const data = {
        value: item.id,
        label: item.name,
      };
      this.filterData[0].properties.dropDownOptions.push(data);
    });

    store.dispatch("preAddFilters", this.filterData).then(() =>
      this.$router.push({
        name: "Employees",
        params: { from: "department" },
      })
    );
  }

  editDepartment(id: number) {
    this.$router.push({
      name: "Edit Department",
      path: "/admin/department/edit",
      params: { departmentId: btoa(id.toString()) },
    });
  }

  async created() {
    await this.initialize();
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }
}
